const CryptoJS = require('crypto-js')

const HMACSHA256_KEY = process.env.VUE_APP_H5_APPSECRET

// sha512加密
export function sha512Encrypt (str: string): string {
  return CryptoJS.SHA512(str).toString()
}

// sha256加密
export function sha256Encrypt (str: string): string {
  return CryptoJS.SHA256(str).toString()
}

// hmacsha256加密
export function hmacSha256Encrypt (str: string, key = HMACSHA256_KEY): string {
  return CryptoJS.HmacSHA256(str, key).toString()
}

// base64加密
export function base64Encrypt (str: string):string {
  const word = CryptoJS.enc.Utf8.parse(str)
  return CryptoJS.enc.Base64.stringify(word)
}

// base64解密
export function base64Decrypt (str: string):string {
  const password = CryptoJS.enc.Base64.parse(str)
  return password.toString(CryptoJS.enc.Utf8)
}
