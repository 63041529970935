<template>
  <router-view v-if="loading" />
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import * as storage from '@/utils/storage'
import { nanoid } from 'nanoid'
import UserAgent from '@/utils/useragent'

const registerDevice = require('@/graphql/mutation/registerDevice.gql')

export default defineComponent({
  setup () {
    const loading = ref(false)
    const { mutate, onDone: registerDeviceDone } = useMutation(registerDevice)
    const deviceId = storage.get('deviceId')
    if (!deviceId) {
      const obj = {
        hwId: nanoid(),
        installId: nanoid(),
        versionName: 'app',
        versionCode: 1,
        platform: UserAgent.isAndroid() ? 'android' : 'ios'
      }
      mutate({ params: obj })
      registerDeviceDone(result => {
        const { registerDevice } = result.data
        if (registerDevice.ok) {
          storage.set('deviceId', registerDevice.data.deviceId)
          loading.value = true
        }
      })
    } else {
      loading.value = true
    }
    return {
      loading
    }
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
