import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Operation } from '@apollo/client/core'
import * as crypoto from '@/utils/crypto'
import * as storage from '@/utils/storage'

dayjs.extend(utc)

interface Sign {
  'gh-nonce': string
  'gh-deviceid': string
  'gh-date': string
  'gh-token': string
  'gh-bodyhash': string
  'gh-appid': string
  'gh-sign': string
}

/**
 * 普通接口签名
 * @param operation
 * @returns
 */
export default function signWrap (operation: Operation): Sign {
  const { query, operationName, variables } = operation
  const queryBody = query.loc?.source.body
  const paramsBody = {
    operationName,
    queryBody,
    variables
  }

  const token = ''
  const nonce = nanoid()
  const deviceid = storage.get('deviceId') as string
  const date = dayjs
    .utc()
    .format()
    .replace(/(-|:)/g, '')
  const bodyHash = crypoto.sha256Encrypt(JSON.stringify(paramsBody)).toUpperCase()
  const appid = process.env.VUE_APP_H5_APPID

  const signStr = `GH-DEVICEID:${deviceid},GH-TOKEN:${token},GH-DATE:${date},GH-NONCE:${nonce},GH-BODYHASH:${bodyHash},GH-APPID:${appid}`
  const signName = crypoto.hmacSha256Encrypt(signStr).toUpperCase()

  return {
    'gh-nonce': nonce,
    'gh-deviceid': deviceid,
    'gh-date': date,
    'gh-token': token,
    'gh-bodyhash': bodyHash,
    'gh-appid': appid,
    'gh-sign': signName
  }
}
