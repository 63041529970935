import { createApp, h, provide } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Field, Checkbox, Form, Icon, Popup, CellGroup, Loading } from 'vant'
import './assets/rem'
import 'vant/lib/index.css'
import './assets/scss/index.scss'
import './assets/iconfont/index.css'
import { DefaultApolloClient } from '@vue/apollo-composable'
import apolloClient from './apollo-client'

createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
}).use(store).use(router).use(Button).use(Field).use(Form).use(CellGroup).use(Popup).use(Icon).use(Checkbox).use(Loading).mount('#app')
