import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from } from '@apollo/client/core'
import { Toast } from 'vant'
import { onError } from '@apollo/client/link/error'
import signWrap from '@/apollo-client/sign'

const cache = new InMemoryCache()
const BASE_URL = process.env.VUE_APP_H5_DMZ_URL

const httpLink = new HttpLink({
  uri: BASE_URL
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    Toast.fail('请求异常，请稍后重试！')
    graphQLErrors.forEach(
      ({ message, locations, path }) => console.log(`
        [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const additiveLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map(data => {
    console.log(data.data)
    return data
  })
})

const apolloClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, httpLink])
})

export default apolloClient
