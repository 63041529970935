class UserAgent {
  ua: string;

  constructor () {
    const a = navigator.userAgent
    this.ua = a.toLocaleLowerCase()
  }

  // 判断是否为在金马证券app内
  isGoldhorseApp () {
    return this.ua.includes('goldhorse-app')
  }

  isIos () {
    return /(iPhone|iPad|iPod|iOS)/i.test(this.ua)
  }

  isAndroid () {
    return /(Android)/i.test(this.ua)
  }
}

export default new UserAgent()
